window.$on('termos.cookies', function(event) {
    console.log(event);
    jQuery.ajax({
        url: 'index.php?route=common/home/cookies',
        success: function(html) {

        }
    });
});

$(function () {
    $('.add').on('click',function(){
        var $qty=$(this).closest('myscript').find('.qty');
        var currentVal = parseInt($qty.val());
        if (!isNaN(currentVal)) {
            $qty.val(currentVal + 1);
        }
    });
    $('.minus').on('click',function(){
        var $qty=$(this).closest('myscript').find('.qty');
        var currentVal = parseInt($qty.val());
        if (!isNaN(currentVal) && currentVal > 1) {
            $qty.val(currentVal - 1);
        }
    });
});