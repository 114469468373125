
// CATEGORIA
var sistemas = $('.categoria__interna');
if (sistemas.length>0){
    $('select[name=\'country_id\']').on('change', function() {
        $.ajax({
            url: 'index.php?route=product/product/country&country_id=30',
            dataType: 'json',
            beforeSend: function() {
                $('select[name=\'country_id\']').after(' <i class="fa fa-circle-o-notch fa-spin"></i>');
            },
            complete: function() {
                $('.fa-spin').remove();
            },
            success: function(json) {
                if (json['postcode_required'] == '1') {
                    $('input[name=\'postcode\']').parent().parent().addClass('required');
                } else {
                    $('input[name=\'postcode\']').parent().parent().removeClass('required');
                }

                html = '<option value=""><?php echo $text_select; ?></option>';

                if (json['zone'] && json['zone'] != '') {
                    for (i = 0; i < json['zone'].length; i++) {
                        html += '<option value="' + json['zone'][i]['zone_id'] + '"';

                        html += '>' + json['zone'][i]['name'] + '</option>';
                    }
                } else {
                    html += '<option value="0" selected="selected"><?php echo $text_none; ?></option>';
                }

                $('select[name=\'zone_id\']').html(html);
            },
            error: function(xhr, ajaxOptions, thrownError) {
                alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    });

    $('select[name=\'country_id\']').trigger('change');

    $(function(){

        $('input[name="postcode"]').mouseout(function(){
            var cep = $.trim($('input[name="postcode"]').val().replace('-', ''));

            $('#simular').removeAttr('disabled');

            $.getJSON("https://viacep.com.br/ws/"+ cep +"/json/?callback=?", function(dados){
                if(!("erro" in dados)){

                    $('select[name="country_id"]').find('option[value="30"]').attr('selected', true);

                    $.post('index.php?route=product/product/estado_autocompletar&estado=' + unescape(dados.uf), function(zone_id){
                        $.ajax({
                            url: 'index.php?route=product/product/country&country_id=30',
                            dataType: 'json',
                            beforeSend: function() {

                                $('select[name=\'country_id\']').after(' <i class="fa fa-circle-o-notch fa-spin"></i>');

                            },
                            complete: function() {
                                $('.fa-spin').remove();
                            },
                            success: function(json) {
                                if (json['postcode_required'] == '1') {
                                    $('input[name=\'postcode\']').parent().parent().addClass('required');

                                } else {
                                    $('input[name=\'postcode\']').parent().parent().removeClass('required');
                                }

                                var html = '<option value=""><?php echo $text_select; ?></option>';

                                if (json['zone'] != '') {
                                    for (i = 0; i < json['zone'].length; i++) {
                                        html += '<option value="' + json['zone'][i]['zone_id'] + '"';

                                        if (json['zone'][i]['zone_id'] == zone_id) {
                                            html += ' selected="selected"';
                                        }

                                        html += '>' + json['zone'][i]['name'] + '</option>';
                                    }
                                } else {
                                    html += '<option value="0" selected="selected"><?php echo $text_none; ?></option>';

                                }

                                $('select[name=\'zone_id\']').html(html);
                            }
                        });
                    });
                }
            });
        });
    });
    $(function(){
        $('select[name="zone_id"]').parent().parent().hide();
        $('select[name="country_id"]').parent().parent().hide();
    });

    jQuery('input[name=\'postcode\']').keydown(function(event) {
        if (event.keyCode == 13) {
            event.preventDefault();
            jQuery('#simular').trigger('click');
            return false;
        }
    });

    $('#simular').on('click',function(){
        $.ajax({
            url:'index.php?route=product/product/quoteProduct',
            type:'post',
            data:$('#options input[type=\'text\'],#options input[type=\'hidden\'],#options input[type=\'radio\']:checked,#options input[type=\'checkbox\']:checked,#options select,#options textarea'),
            dataType:'json',
            beforeSend:function(){
                $('#simular').attr('disabled', true).text('Aguarde...');
            },
            complete:function(){
            },
            success:function(json){
                $('.alert, .text-danger').remove();
                $.ajax({
                    url:'index.php?route=product/product/quote',
                    type:'post',
                    data:'country_id='+$('select[name=\'country_id\']').val()+'&zone_id='+$('select[name=\'zone_id\']').val()+'&postcode='+encodeURIComponent($('input[name=\'postcode\']').val()),
                    dataType:'json',
                    complete: function(){
                        $('#simular').attr('disabled', false).text('Calcular');
                    },
                    success:function(json){
                        if(json['error']){
                            if(json['error']['warning']){
                                $('#notification').html('<div class="alert alert-danger"><i class="fa fa-exclamation-circle"></i> '+json['error']['warning']+'<button type="button" class="close" data-dismiss="alert">&times;</button></div>');
                                $('html, body').animate({ scrollTop: 0 }, 500);
                            }
                            if(json['error']['postcode']){
                                alert("Atenção: Verifique o CEP Digitado!")
                            }
                        }
                        if(json['shipping_method']){
                            $('#div-shipping').remove();
                            html='<div id="div-shipping">';
                            html+='  <div class="modal-dialog">';
                            html+='    <div class="modal-content">';
                            html+='      <div class="modal-header">';
                            html+='        <h4 class="modal-title"><strong><i style="font-size:15px;" class="fa fa-truck"> </i> </strong>Frete:</h4>';
                            html+='      </div>';
                            html+='      <div class="modal-body">';
                            for(i in json['shipping_method']){
                                html+='<p><strong>'+json['shipping_method'][i]['title']+'</strong></p>';
                                if(!json['shipping_method'][i]['error']){
                                    for(j in json['shipping_method'][i]['quote']){
                                        html+='<div class="radio">';
                                        html+='  <label>';
                                        html+=json['shipping_method'][i]['quote'][j]['title']+' - '+json['shipping_method'][i]['quote'][j]['text']+'</label></div>'
                                    }
                                }  else {
                                    html+='<div class="alert alert-danger">'+json['shipping_method'][i]['error']+'</div>'}}
                            html+='      </div>';
                            html+='    </div>';
                            html+='  </div>';
                            html+='</div> ';
                            $('#escolher-frete').html(html);
                            //$('#modal-shipping').modal('show');
                            $('#simular').prop('disabled',true);
                            $('input[name=\'shipping_method\']').on('change',function(){
                                $('#button-shipping').prop('disabled',false);
                            });
                        }
                    }
                })},
            error:function(xhr,ajaxOptions,thrownError){
                $('#simular').attr('disabled', false).text('Calcular');
                alert(thrownError+"\r\n"+xhr.statusText+"\r\n"+xhr.responseText);}})});
    jQuery('#form-avaliar').on('submit', function(event) {
        var product_id = $(this).data('product');
        event.preventDefault();
        jQuery.ajax({
            url: 'index.php?route=product/product/write&product_id='+product_id,
            type: 'post',
            dataType: 'json',
            data: 'name=' + encodeURIComponent(jQuery('#form-avaliar input[name=\'name\']').val()) + '&text=' + encodeURIComponent(jQuery('#form-avaliar textarea[name=\'text\']').val()) + '&rating=' + encodeURIComponent(jQuery('#form-avaliar input[name=\'rating\']:checked').val()),
            beforeSend: function() {
                jQuery('#form-avaliar .alert').remove();
                jQuery('#form-avaliar button[type="submit"]').attr('disabled', true).before('<div class="wait"><i class="fa fa-circle-o-notch fa-spin"></i> Aguarde</div>');
            },
            complete: function() {
                jQuery('#botao-avaliar').attr('disabled', false);
                jQuery('.wait').remove();
            },
            success: function(data) {
                if (data['error']) {
                    jQuery('#form-avaliar').prepend('<div class="alert alert-danger"><span class="fa-stack fa-lg"><i class="fa fa-exclamation-triangle fa-stack-2x active"></i></span><span> ' + data['error'] + '</span></div>');
                }

                if (data['success']) {
                    jQuery('#form-avaliar').prepend('<div class="alert alert-success"><span class="fa-stack fa-lg"><i class="fa fa-thumbs-up fa-stack-2x active"></i></span><span> ' + data['success'] + '</span></div>');
                    jQuery('input[name=\'name\']').val(null);
                    jQuery('textarea[name=\'text\']').val(null);
                }
            }
        });
    });
    jQuery('#button-cart').on('click', function() {
        jQuery('.obrigatoriedades').addClass('hidden');
        jQuery.ajax({
            url: 'index.php?route=checkout/cart/add',
            type: 'post',
            data: jQuery('#options input[type=\'text\'],#options input[type=\'number\'], #options input[type=\'hidden\'], #options input[type=\'radio\']:checked, #options input[type=\'checkbox\']:checked, #options select, #options textarea, #input-cor, #input-tamanho'),
            dataType: 'json',
            beforeSend: function(r) {
            },
            success: function(json) {
                if (json['error']) {
                    if (json['error']['option']) {
                        for (i in json['error']['option']) {
                            jQuery('#options').before('<div class="alert alert-danger obrigatoriedades mgt-20">' + json['error']['option'][i] + '</div>');
                        }
                    }
                }
                jQuery('#carrinho').html(json['total']);

                if (json['success']) {
                    location='index.php?route=checkout/cart';
                }
            }
        });
        return false;
    });
    var AviseMe = {
        open: function() {
            $('#modal_avise_me_quando_chegar').modal('show');
        },
        product: function(product_id) {
            $('#avise-me-product-id').val(product_id);
            $('#avise-me-option-value-id').val(null);
        },
        productOption: function(product_id, option_value_id) {
            $('#avise-me-product-id').val(product_id);
            $('#avise-me-option-value-id').val(option_value_id);
        },
        submit: function() {
            jQuery.ajax({
                url: jQuery('#form-avise-me').attr('data-pra'),
                type: 'post',
                dataType: 'json',
                data: jQuery('#form-avise-me input'),
                beforeSend: function() {
                    jQuery('#avise-me-notification .alert').remove();
                    jQuery('#botao-aviseme').attr('disabled', true);
                },
                complete: function(data) {
                    jQuery('#botao-aviseme').attr('disabled', false);
                },
                success: function(data) {
                    if (data['erro']) {
                        $('#avise-me-notification').append('<div class="alert alert-danger"><span class="fa-stack fa-lg"><i class="fa fa-exclamation-triangle fa-stack-2x active"></i></span><span> ' + data['erro'] + '</span></div>');
                    }

                    if (data['sucesso']) {
                        alert(data['sucesso']);
                        $('#modal_avise_me_quando_chegar').modal('hide');
                    }
                }
            });
        }
    }
    var selectedName = "";
    var originalPrice = "<?php echo ($special) ? $special : $price; ?>";
    var originalParcelamento = "<?php echo $parcelamento; ?>";
    function checkCombinations() {
        var values = [],
            sels = $('#options input[type="radio"]:checked, #options input[type="checkbox"]:checked, #options select>option:selected'),
            total = sels.length,
            name = "";
        if (total > 0) {
            for (var i=0; i<total; i++) {
                if (sels[i].tagName == 'OPTION') {
                    name = sels[i].parentElement.name;
                } else {
                    name = sels[i].name;
                }
                //if (isSelectedName(name)) {
                values.push(sels[i].getAttribute('data-option-value'));
                //}
            }
            $.ajax({
                url: 'index.php?route=product/product/combination_values&product_id=<?php echo (int)$product_id; ?>&option_value_id=' + values.join(','),
                dataType: 'json',
                beforeSend: function() {
                    $('.no-stock').remove();
                    $('#button-aviseme').hide();
                },
                success: function(r){
                    if (r.option_values) {
                        $('#options input[type="radio"], #options input[type="checkbox"]').each(function(){
                            var ovid = this.getAttribute('data-option-value');
                            if (ovid === null || isSelectedName(this.name) || r.option_values.indexOf(ovid) > -1) {
                                $(this).show().next().show();
                            } else {
                                $(this).hide().next().hide();
                                this.checked = false;
                            }
                        });
                        $('#options select > option').each(function(){
                            var ovid = this.getAttribute('data-option-value');
                            if (ovid === null || isSelectedName(this.parentElement.name) || r.option_values.indexOf(ovid) > -1) {
                                $(this).show();
                            } else {
                                $(this).hide().parent().find('option:visible').attr('selected', true);
                            }
                        });
                    }
                    if (r.combination_info) {
                        if (r.combination_info['quantity'] < 1) {
                            if (typeof AviseMe !== "undefined") {AviseMe.productOption(0, values[0]);}
                            $('#button-aviseme').show();
                            $('#button-cart').hide(); //.after('<div class="form-group text-danger no-stock">Produto indisponível no momento</div>');
                        } else {
                            $('#button-cart').show();
                            $('.no-stock').remove();
                            $('#button-aviseme').hide();
                        }
                        if (typeof atualizarPreco === "function") {
                            atualizarPreco(r.combination_info);
                        } else {
                            if (r.combination_info['price']) {
                                $('#preco-produto').text(r.combination_info['price']);
                            } else {
                                $('#preco-produto').text(originalPrice);
                            }
                            if (r.combination_info['parcelamento']) {
                                $('#parcelamento').text(r.combination_info['parcelamento']);
                            } else {
                                $('#parcelamento').text(originalParcelamento);
                            }
                        }
                    } else {
                        if (typeof AviseMe !== "undefined") {AviseMe.productOption(0, values[0]);}

                        if (r.messages) {
                            var messages = [];
                            for (m in r.messages) {
                                messages.push(r.messages[m]);
                            }
                            $('#button-cart').hide().after('<div class="form-group text-danger no-stock">'+messages.join('<br>')+'</div>');
                        } else {
                            //$('#button-cart').hide();
                            $('#button-aviseme').show();
                        }
                        $('#preco-produto').text(originalPrice);
                        $('#parcelamento').text(originalParcelamento);
                    }
                }
            });
        } else {
            $('input[data-option-value]:hidden, label[data-option-value]:hidden').show();
            setSelectedName(null);
        }
    }
    $('#options input[type="radio"], #options select').on('change', function(){
        setSelectedName(this.name);
        checkCombinations();
    });
    function isSelectedName(name) {
        return (selectedName == name);
    }
    function setSelectedName(name) {
        selectedName = name;
    }
    window.addEventListener('load', function(){

        var option_values = [],
            options = document.body.querySelectorAll('#options input[type="radio"], #options select > option'),
            i, total;
        if (options && options.length > 1) {
            for (i=0, total=options.length; i<total; i++) {
                if (options[i].value in option_values) {
                    options[i].setAttribute("data-option-value", option_values[options[i].value]);
                }
            }
        }
    });
}